body{
  margin:0;
  background-color: #eeeeee;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  line-height: 1.43;
  color: #3d404e;
}
.main{
  display: block;
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 0;
  /* left: 200px; */
  transition: all .3s ease-in-out;
}
.container-new{
  box-sizing: border-box;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
}
@media(max-width: 500px){
  .container-new{
      padding: 10px;
  }
}
.container{
  width: 100%;
  position: relative;
  height: 100%;
} 
.client-bg {
  background-color: #eeeeee;
}
.canvas-shrink{
  margin-left: 215px;
}
.content-container{
  padding-top: 4vmax;
}
.global-loader{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.canvas{
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.canvas-wide{
  margin-left: 50px;
} 
.red{
  color: #ff6a7f;
}
.green{
  color: #5bdd5e;
}
.flex-container-app{
  max-width: 1400px;
}
.col1-app{
  flex: 15%;
  background-color: #012f46;
  top:20vmax;  
}
.col2-app{
  flex: 85%;
  top:20vmax; 
}
.rl-hide{
  display: none !important;
}
.rl-hidden{
  visibility: hidden !important;
}
.ease{
  transition: all 0.4s ease 0s;
}
.bg-white{
  background-color: white;
}
.btn-none{
  border:none;
}
.pointer{
  cursor: pointer;
}
.v-align-middle{
  vertical-align: middle;
}
.no-scroll{
  margin: 0; height: 100%; overflow: hidden
}
.label{
  color: #333333;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 700;
}
.form-error{
  color: #c82506;
  font-size: 12px;
}
.form-control{
  display: block;
  width: 95%;
  height: 34px;
  padding: 0px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
input.form-control{
  width: 90%;
}
input.form-control-fullwdth{
  width: 100%;
}
input[type='file' i].form-control{
  padding: 13px 12px 0px 12px;
  min-height: 56px;
}
.react-datepicker__input-container input{
  width: 90%;
  display: block;
  height: 34px;
  padding: 0px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[readOnly]{
  background: #eeeeee;
  cursor: not-allowed;
}

@media (max-width: 950px){
  .canvas-wide--mobile{
    margin-left: 0px !important;
  } 
}

/* css for input type range */
input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #3071a9;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #367ebd;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #3071a9;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}
/* css for input type range */

/* Transition Properties */
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

/* Transition Properties */

/* overriding datepicker css */
.react-datepicker-wrapper{
  width: 95% !important;
}
/* overriding datepicker css */

/*overriding form-step css scemantic ui*/
.ui.ordered.steps .step:before {
  font-size: 1.5em !important;
}
/*overriding form-step css scemantic ui*/
.truncate{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
h4.ui.header{
  margin: 0 !important;
  line-height: 33px !important;
}
select:disabled{
  background-color:#eeeeee;
}
input:disabled{
  background-color:#eeeeee;
}
textarea:disabled{
  background-color:#eeeeee;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bfb8b8;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #bfb8b8;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #bfb8b8;
}

.iframe-placeholder
{
   background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="15" text-anchor="middle">Loading...</text></svg>') 0px 0px no-repeat;
}

.popUp-modal--wrapper {
  margin: 18px 18px 12px 18px;
  background: #fff;
  padding: 12px;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}