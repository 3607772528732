.cust-reach{
    background-color: #dfe2e4;
    position: relative;
    padding: 23px 0px;
    margin: 5% 5% 3% 0;
    border-radius: 0 12px 12px 0;
}
.cust-reach h1 {
    font-weight: 700;
    color: #000;
    margin: 0;
    padding-left: 2vw;
    font-size: 2.5vw;
}
@media(max-width: 550px){
    .cust-reach h1 {
        font-size: 5.5vw;
    }
}
.cust-reach img {
    position: absolute;
    top: -23px;
    right: 30px;
    width: 21vw;
}
@media(max-width: 550px){
    .cust-reach img {
        top: -55px;
        left: 30%;
        width: 31vw;
    }
}
@media(min-width:920px){
    .cust-reach img {
        width: 18vw !important;
    }   
}
@media(min-width:920px){
    .cust-reach img {
        width: 16vw !important;
    }   
}
.banners-container{
    display: flex;
    padding: 5% 0 12px 0;
    justify-content: space-between;
}
@media(max-width: 550px){
    .banners-container{
        flex-direction: column !important;
       
    }
}
.banners-container > .banner {
    width: 22%;
    text-align: center;
    background-color: white;
    border-radius: 4px;
    padding: 12px;
}
@media(max-width:500px){
    .banners-container > .banner{
        width: 100% !important; 
        margin: 5px 0;
    }
}
.banners-container > .banner > img {
    width: 70%;
    margin: 0 auto 12px auto;
    display: block;
}
@media(max-width: 490px){
    .banners-container > .banner > .banner-imgSpan > img{
        width: 15% !important;
        margin: 0;
    }
}
@media(max-width: 490px){
    .banner-imgSpan >img{
        -moz-float-edge: left;
        float: left;
    }
}
.banners-container > .banner > .banner-imgSpan{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}
@media(max-width: 500px){
    .banners-container > .banner > .banner-imgSpan{
        font-size: 15px;
    }
}
.banners-container > .banner:hover {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.getStarted{
    background-color: #d9d9d9;
    margin-top: 8px;
    border-radius: 12px;
}
.getStarted > .caption{
    display: inline-block;
    font-weight: bold;
    margin: 0px;
    width: 80%;
    text-align: center;
    font-size: 26px;
}
.getStarted-content{
    width: 100%;
    align-items: center;
    color: #222;
}
.anchor-btn{
    background-color: #ff5757;
    padding: 8px 40px;
    color: #fff;
    border: none;
    font-size: 20px;
    text-decoration: none;
}
.getStarted-btn{
    background-color: #ff5757;
    border-radius: 5px;
    /* padding-bottom: 8px; */
    color: #fff;
    border: none;
    font-size: 18px;
    cursor: pointer;
    float: right;
    text-decoration: none;
}

@media(max-width: 500px){
    .getStarted-btn{
        font-size: 15px; 
    }
}

