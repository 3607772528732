.dashboard{
    padding:12px;
}
.wallet-home{
    align-items: center;
    border-radius: 4px;
    font-weight: 150;
    font-size: large;
    background: linear-gradient(to right, rgba(49,137,184,.01) 0 ,rgba(49,137,184,.24) 100%);
    /* cursor: pointer; */
}
.credit-limit{
    position: 'absolute';
    bottom: '3px';
    right: '0.5vw';
    left:'-32px';
    text-align:'center';
}
@media(max-width:500px){
    .credit-limit{
        top:'55px'; 
    }
}
.wallet-home__icon{
    width: 6vw;
}
@media(max-width:500px){
    .wallet-home__icon{
        width: 20vw;
    }
}