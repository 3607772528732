
.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background: #fff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}
/* .btn-small{
    padding: 4px 6px !important; 
    margin-top:0.2 !important; 
    margin-bottom: 0!important; 
    font-size: small !important;
} */
.btn.btn-fill {
    color: #fff;
    background-color: #8a8989 !important;
    opacity: 1;
    filter: alpha(opacity=100);
}
.btn-primary.btn-fill {
    color: #fff;
    background-color: #447df7 !important;
    opacity: 1;
    filter: alpha(opacity=100);
}
.btn-info.btn-fill {
    color: #fff;
    background-color: #23ccef !important;
    opacity: 1;
    filter: alpha(opacity=100);
}

.btn-servicePackage.btn-fill {
    color: #fff;
    background-color: #4183c4 !important;
    /* background-color:#8a8989; */
    padding:18px 14px !important;
    opacity: 1;
    filter: alpha(opacity=100);
}

.btn-blue.btn-fill {
    color: #fff;
    background-color: #0068ac !important;
    opacity: 1;
    filter: alpha(opacity=100);
}
.btn-green.btn-fill{
    color: #fff;
    background-color: #4CAF50 !important;
    opacity: 1;
    filter: alpha(opacity=100);
}

.btn-orange.btn-fill{
    background-color: rgba(240,90,34,1) !important;
    color:#fff;
    opacity: 1;
    filter: alpha(opacity=100);
}

.btn-success.btn-fill {
    color:  #fff;
    background-color: #68ab2e !important;
    opacity: 1;
    filter: alpha(opacity=100);
}

.btn-expletus.btn-fill {
    color: #fff;
    background-color: #ff5757 !important;
    opacity: 1;
    filter: alpha(opacity=100);
}
.btn-warning.btn-fill {
    color: #fff;
    background-color: #ffa534 !important;
    opacity: 1;
    filter: alpha(opacity=100);
}
.btn-danger.btn-fill {
    color: #fff;
    background-color: #fb404b !important;
    opacity: 1;
    filter: alpha(opacity=100);
}
.btn-success {
    border-color: #68ab2e;
    color: #68ab2e;
}
.btn-blue{
    border-color:#0068ac !important;
    
}
.btn-green{
    color: #4CAF50;
}
.btn-expletus{
    border-color: #ff5757;
    color: #ff5757;
}
.btn-danger {
    border-color: #fb404b;
    color: #fb404b;
}
.btn-info {
    border-color: #23ccef;
    color: #23ccef;
    
}
.btn-warning {
    border-color: #ffa534;
    color: #ffa534;
}

.btn-orange{
    border-color: rgba(240,90,34,1);
    color:rgba(240,90,34,1);
}
    

