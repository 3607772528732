.side-bar{
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 200px;
  color: #fff;
  background: #3d404e;
  font-size: 13px;
  line-height: 30px;
  transition: all .3s ease-in-out; 
}
@media(max-width:500px){
  .side-bar{
    z-index:1;
  }
}
.menu, .exp_menu__list {
  display: block;
  background: #3d404e;
}
.exp_menu__list {
  margin-top: 16px;
  padding: 0;
  height: 100%;
  overflow: auto;
  padding-bottom: 60px;
}
.exp_menu__title p {
  font-size: 13px;
  line-height: 1.54;
  text-transform: uppercase;
  color: hsla(0,0%,100%,.5);
  font-weight: 700;
}
.exp_menu__element{
    list-style-type: none;
    display: block;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 4px 4px 4px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: background-color .1s ease-out;
}
.exp_menu__link.active{
  color: #fff;
  opacity: 1;
  background: hsla(0,0%,100%,.23);
  border-radius: 4px;
}
.exp_menu__link{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    padding: 4px 0 4px 12px;
}
.exp_menu__link svg {
  margin-right: 8px;
  display: inline-block;
  color: hsla(0,0%,100%,.45);
}

.exp_menu__link span {
  font-weight: 300;
  color: hsla(0,0%,100%,.75);
}

ul::-webkit-scrollbar {
  width: 8px;
}

ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

ul::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #a2a1a1;
  /* -webkit-box-shadow: inset 0 0 6px #eeeeee;  */
}
li .nav{
  display: block;
  color: #fff;
  padding: 10px 12px;
  margin: 0 8px;
  min-width: 170px;
  text-decoration: none;
  font-size: small;
}
.nav.collapse{
  margin: 0px !important;
  border-radius: 0px !important;
}
li .nav:hover {
  color: #fff;
  opacity: 1;
  background: hsla(0,0%,100%,.23);
  border-radius: 4px;
  /* transform: scale(1.06); */
}
.nav.active{
  color: #fff;
  opacity: 1;
  background: hsla(0,0%,100%,.23);
  border-radius: 4px;
}
i{
  margin: 6px;
}
.sidebarItem{
  margin-left: 14px;
  vertical-align: middle;
  opacity: 0.75;
}
.sidebar-list{
  border-radius: 0px;
}
