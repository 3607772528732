.fill-container{
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #eeeeee;
}
.medlife.form-submit-btm{
    display: block;
    border-style: none;
    border-radius: 4px;
    z-index: 60;
    width: 100%;
    height: 40px;
    background: #0c73a5;
    box-shadow: none;
    text-shadow: none;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.medlife label{
    margin: 0 !important;
}
.medlife.header{
    text-align: center;
    /* line-height: 34px; */
}
@media (max-width: 950px){
    .medlife.header{
        line-height: 14px !important;
    } 
}
.medlife.header.text{
    font-weight: 700;
    font-family: "Josefin Sans";
    font-size: 28px;
    color: rgb(12, 115, 165);
}
@media (max-width: 950px){
    .medlife.header.text{
        font-size: 15px !important;
    } 
  }
.iframe-input{
    height: 32px !important;
    line-height: 14px !important;
}
.medlife select{
    height: 32px !important;
    line-height: 13px !important;
    font-size: 14px !important;
    padding: 0 12px !important;
}
.medlife .required.field{
    margin: 0 0 3px !important;
}
#data-form-dynamic{
    position: absolute;
    top: 65%;
    left: 70%;
    width: 25%;
    text-align: start;
    background-color: rgba(42, 173, 239,0.1);
    padding:10px;
    border-radius: 20px;
    transform: translate(-50%,-50%);
}
@media (max-width: 950px){
    #data-form-dynamic{
        position: absolute;
        /* top: 65%; */
        top:240%;
        left: 50%;
        width: 95%;
        text-align: start;
        background-color: rgba(42, 173, 239,0.1);
        padding:20px;
        border-radius: 20px;
        transform: translate(-50%,-50%);
    } 
  }

#data-form{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%,-50%);
}
  /* .col-75 {
   margin-left:40%
  } */

  #data-form-otp{
    margin-top: 5%;
    width: 30%;
    background-color: #F2FCFF;
    margin-left: auto;
    margin-right: auto;
    padding:2.5%;
}