
.container {
    position: relative;
    /* width: 100%; */
    overflow: hidden;
    /* padding-top: 50%; */
    margin-left: auto;
    margin-right: auto;
   /* padding-top: 75%;  4:3 Aspect Ratio */
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: contain;
    /* width: 100%; */
    /* height: 100%; */
    border: none;
  }