.sender-id--wrapper{
    margin: 18px 18px 12px 18px;
    background: #fff;
    padding: 12px;
}
.sender-note{
    background-color: #dfe2e4 ;
    padding: 5px;
}
.senderId-action--wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.sender-ids--cta{
    font-size: 16px !important;
    line-height: 30px !important;
    border-radius: 8px !important;
    padding: 4px 26px !important;
}
#entries{
    margin: 0 3px;
}
.senderId-utility--wrapper{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.senderId-pagination{
    font-size: 14px;
}


