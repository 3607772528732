.header-custom{
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(61,64,78,.12), inset 0 -1px 0 0 rgba(61,64,78,.12);
  z-index: 100;
}
.header-wide{
  width: calc(100% - 50px) !important;
}
.header-wide-mobile{
  max-width: 100%;
}
.menuBar-open{
  cursor: pointer;
  position: absolute;
  left: 11%;
}
.menuBar-close{
  cursor: pointer;
  position: absolute;
  left: 3%;
}
.menuBar > div{
  width: 25px;
  height: 3px;
  background-color: #222222;
  margin: 4px 0;   
}
.menuBar{
  transition: all 0.4s ease 0s;
}
.welcome-note{
  position: absolute;
  right: 30px;
  top: -4px;
}

/* @media (max-width: 950px){
  .header--mobile{
    width: 100% !important;
    height: 8vmax !important;
  }
  .menuBar-open--mobile {
    left: 20% !important;
  }
}  */