.scroll-mask{
    position: fixed;
    background-color: rgba(0,0,0,.35);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.scroll-mask-bar{
    display: block;
    position: absolute;
    background-color: #fafafa;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 65;
    box-shadow: inset 0 0 1px rgba(0,0,0,.3);
}
.dialog-container{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 80;
    /* overflow: hidden; */
}
.dialog{
    z-index: 100 !important;
    /* max-width: 600px; */
    /* min-width: 600px;  For Mobile Version */
    /* max-height: 590px;  For Mobile Version*/
    width: 80%;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    /* overflow: hidden; */
    box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 4px;
    background-color: rgb(255,255,255);
    opacity: 1;
    transition: all .6s cubic-bezier(.25,.8,.25,1);
    -webkit-transform: translate(0,0) scale(1);
    transform: translate(0,0) scale(1);
}
@media(max-width:500px){
    .dialog{
        max-height: 530px;
    }
}
.dialog-header{
    background-color: #01283b;
    color: rgb(255,255,255);
    min-height: 51px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    z-index: 2;
    font-size: 20px;
    min-height: 64px;
    width: 100%;
}
.toolbar-tools{
    height: 51px;
    max-height: 51px;
    font-size: 20px;
    letter-spacing: .005em;
    box-sizing: border-box;
    font-weight: 400;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    height: 64px;
    max-height: 64px;
    padding: 0 16px;
    margin: 0;
    justify-content: space-between;
}
.dialog-footer{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #bfbfbf;
}
.dialog--cta{
    padding: 8px 24px;
    font-size: 14px;
    border-radius: 4px;
}
.zero-height{
    max-height: 0px;
}
