
.modal{
    top:50%;
    left:50%;
    width:75%;
    transform: translate(-50%,-50%) scale(0);
    position: fixed;
    transition: 200ms ease-in-out;
    z-index: 10;
    background-color: white;
    overflow-x: auto;
    overflow-y: auto;
}
.modal.active{
    transform: translate(-50%,-50%) scale(1);
}

@media(max-width: 490px){
    .modal{
        height:80%;
        width:100%;
    }
}
.modal-header{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #bdbbb9;
    padding: 3%;
}
@media(max-width: 490px){
    .modal-header{
        padding: 0 3%;
    }
}
.modal-header .close-button{
    cursor: pointer;
    border: none;
    font-size: 2.5rem;
    font-weight: bold;
}
@media(max-width: 490px){
    .modal-header .close-button{
        font-size: 2.0rem;
    }
}
.modal-body{
    padding: 15px;
}
.overlay{
    position: fixed;
    /* pointer-events: none; */
    /* opacity: 0; */
    top:0;
    left:0;
    right:0;
    bottom: 0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.3);
}
/* #overlay .active{
    opacity: 1; 
    pointer-events: all;
} */