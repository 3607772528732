.leads-table-wrapper{
    overflow-y: auto;
    position: relative;
    overflow-x: auto;
    /* max-height: 70vh; */
}
.leads-table-footer{
    position: fixed;
    z-index: 10;
    right: 30px;
    bottom: 0;
    /* left: 210px; */
}
.client {
    border-collapse: collapse;
    width: 100%;
    color: #3d404e;
    font-size: 15px;
  }
  @media only screen and (max-width: 500px){
    .client {
      font-size: 13px;
    }
  }
  
  .client td, .customers th {
    /* border: 1px solid #f1f1f1; */
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  /* tbody>tr:nth-child(even){background-color: #fbfbfb;} */
  tbody>tr:nth-child(even){background-color: rgba(230,230,230,0.3);}
  tbody>tr:nth-child(odd){background-color: white;}
  
  .client tr:hover {background-color: #ddd;}
  
  .client th {
    padding: 8px;
    text-align: left;

    background-color: #fff;
    font-weight: bold;
    color: black;
    border: 1px solid #f1f1f1;
    line-height: 1.36rem;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: sticky;
    top: 0;
  }