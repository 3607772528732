/* Style the accordion section */
.accordion__section {
    display: flex;
    flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    /* background-color: #eeeeee; */
    color: #4d4d4d;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
    background-color: #ccc;
}

/* Style the accordion content title */
.accordion__title {
    font-weight: 600;
    font-size: 2rem;
    /* font-size: 22px; */
    text-align: left;
}
@media(max-width:500px){
    .accordion__title {
        font-size: 1rem;
    }
}

/* Style the accordion chevron icon */
.accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
    transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
    background-color: white;
    overflow: auto;
    transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
}
  