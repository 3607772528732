
.card-border{
    border: 1px solid #DEDDDC;
    width: 295px;
    height: 282px;
    background: #FFFFFF;
    border-radius: 0px;
}
.card-border:hover{
    border: 1px solid  #367D8A;
}

@media(max-width: 490px){
    .card-border{
        width: 230.1px;
        height: 219.96px;
    }
}

.dashboard-headline{
    border: 1px solid #DEDDDC;
    margin: 16px;
    padding: 2%;
}
.dashboard-headline:hover{
    border: 1px solid  #367D8A;
}


.cta-btn{
    border: 1px solid #DEDDDC;
    padding:8px;
    border-radius: 6px;
    font-size: 1rem;
}

.cta-btn:hover{
    background-color: #367D8A;
    color: #FFFFFF;
}