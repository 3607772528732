.card-custom{
    position: relative; 
    margin: .5rem 0 1rem; 
    background-color: #fff; 
    transition: box-shadow .25s; 
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.bdr-btm{
    border-bottom: 1px solid #dce0e0;
}

.bdr-btm--wht{
    border-bottom: 2px solid #ffffff;
}

.bdr-rt{
    border-right: 1px solid #dce0e0;
}

.bdr-rt--wht{
    border-right: 2px solid #ffffff;
}

.bdr-btm-2{
    border-bottom: 2px solid;
}

.bdr-radius-8px{
    border-radius: 8px;
}

.flex-space--btw{
    justify-content: space-between;
}

.flex{
    display: flex;
}

.flex-wrap{
    flex-wrap: wrap;
}

.justify-content--center{
    justify-content: center;
}
.flex-direction--row{
    flex-direction: row;
}

.flex-direction--col{
    flex-direction: column;
}

.flex-align--center{
    align-items: center;
}

.flex-horz-center{
    justify-content: center;
}
.algn-vert-center{
    align-items: center;
}

.align-space-between{
    justify-content: space-between;
}
.vert-space-between{
    align-content: space-around;
}
.align-space-around{
    justify-content:space-around;
}
.justify-flex-end{
    justify-content: flex-end;
}
.col-auto{
    width: auto !important;
}

.col-1{
    width: 5% !important;
}

.col-2{
    width: 10% !important;
}
.col-3{
    width: 15% !important;
}

.col-4{
    width: 20% !important;
}

.col-5{
    width: 25% !important;
}
.col-6{
    width: 30% !important;
}

.col-7{
    width: 35% !important;
}

.col-8{
    width: 40% !important;
}

.col-9{
    width: 45% !important;
}

.col-10{
    width: 50% !important;
}

.col-11{
    width: 55% !important;
}

.col-12{
    width: 60% !important;
}

.col-13{
    width: 65% !important;
}

.col-14{
    width: 70% !important;
}

.col-15{
    width: 75% !important;
}

.col-16{
    width: 80% !important;
}

.col-17{
    width: 85% !important;
}

.col-18{
    width: 90% !important;
}

.col-19{
    width: 95% !important;
}

.col-20{
    width: 100% !important;
}