.pad{
    padding: 16px;
}
.pad--half{
    padding: 8px;
}
.pad--quar{
    padding: 4px;
}
.pad--d{
    padding: 2px;    
}
.pad-two{
    padding: 2%;
}
.padding-top--auto{
    padding-top: auto !important;
}
.padding-btm-auto{
    padding-bottom: auto !important;
}
.padding-left--auto{
    padding-left: auto !important;
}
.padding-right--auto{
    padding-right: auto !important;
}
.padding-top{
    padding-top: 16px;
}
.padding-top-three{
    padding-top: 3%;
}
.padding-top-five{
    padding-top: 5%;
}
.padding-top-six{
    padding-top: 6%;
}
.padding-top-tewleve{
    padding-top: 12%;
}
.padding-top--half{
    padding-top: 8px;
}
.padding-top--quar{
    padding-top: 4px;
}
.padding-btm{
    padding-bottom: 16px;
}
.padding-btm--half{
    padding-bottom: 8px;
}
.padding-btm--quar{
    padding-bottom: 4px;
}
.padding-btm-Three{
    padding-bottom: 3%;
}
.padding-btm-One{
    padding-bottom: 1%;
}
.padding-left{
    padding-left: 16px;
}
.padding-left--half{
    padding-left: 8px;
}
.padding-left--quar{
    padding-left: 4px;
}
.padding-right{
    padding-right: 16px;
}
.padding-right--half{
    padding-right: 8px;
}
.padding-right--quar{
    padding-right: 4px;
}